import axios from 'axios'
import md5 from 'js-md5'

let baseURL = 'http://192.168.31.81:4000/'
// let baseURL = 'https://api-web.n2yun.com.cn/'

if (process.env.NODE_ENV === 'production') {
  baseURL = 'https://api-web.n2yun.com.cn/'
}

localStorage.setItem('local_time', 0)

async function startGetTime() {
  let url = 'https://j.n2yun.com.cn/api/server/_stm'
  let res = await axios.get(url)
  let Time = parseInt(res.data.server_time)
  let localTime = Date.now()

  localStorage.setItem('local_time', Time - localTime)
}

startGetTime()

function getTime() {
  var Time = localStorage.getItem('local_time')
  var localTime = Date.now()
  var adjustedTime = localTime + (Time ? parseInt(Time) : 0)

  return parseInt(adjustedTime)
}

const instance = axios.create({
  baseURL: baseURL,
  timeout: 3500,
  headers: {
    'Content-Type': 'application/json'
  }
})

const Token = 'IqBfMawdecJEib54EWZcud'

function getCookie(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${name}=`)) {
      return cookie.substring(name.length + 1);
    }
  }
  return null;
}

instance.interceptors.request.use(
  config => {
    if (config.method === 'get') {
      let relUrl = config.url
      const ctime = getTime()

      if (relUrl.indexOf("?") !== -1) {
        relUrl = relUrl.split("?")[0]
      }

      const sign = md5(relUrl + ctime + Token)

      if (config.params) {
        config.params.token = getToken()
        config.params.sign = sign
        config.params.signctime = ctime
      } else {
        config.params = {
          token: getToken(),
          sign: sign,
          signctime: ctime
        }
      }
    } else {
      let relUrl = config.url
      const ctime = getTime()

      if (relUrl.indexOf("?") !== -1) {
        relUrl = relUrl.split("?")[0]
      }

      const sign = md5(relUrl + ctime + Token)

      if (config.data) {
        config.data.token = getToken()
      } else {
        config.data = {
          token: getToken()
        }
      }

      if (config.params) {
        config.params.sign = sign
        config.params.signctime = ctime
      } else {
        config.params = {
          sign: sign,
          signctime: ctime
        }
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

//  获取本地的token
function getToken() {
  return getCookie('n2yunToken')
}

export default instance