import React from 'react'
import '../css/Home.css'

import { NotificationOutlined, HddOutlined } from '@ant-design/icons'
import { Alert, FloatButton, Layout, Result, message, ConfigProvider, Typography, Modal, Select } from 'antd'
import { BrowserRouter, useRoutes, useNavigate } from 'react-router-dom'
import api from './api'
import zhCN from 'antd/locale/zh_CN'

import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { StyleProvider } from '@ant-design/cssinjs'
import { BranchesOutlined } from '@ant-design/icons'

import NProgress from "nprogress"
import "nprogress/nprogress.css"

import Nav from './Nav'
import Region from './Region'
import Grid from './Grid'
import Article from './Article'
import Notify from './Notify'
import Console from './Console'
import Admin from './Admin'
import Status from './Status'
import Login from './Login'
import Register from './Register'
import Nzadmin from './Nzadmin'
import Edit from './Edit'
import Rely_Edit from './Rely_Edit'
import User from './User'
import Verify from './Verify'
// import Sso from './Sso'
// import ForgetPassword from './Forget_Password'
import md5 from 'js-md5'


const { Text, Link } = Typography
const { Footer } = Layout
dayjs.locale('zh-cn')


//  清理本地 Cookie 
function clearCookie(name) {
    const expires = new Date(0)
    if (process.env.NODE_ENV === 'production') {
        document.cookie = `${name}=; expires=${expires.toUTCString()}; domain=.n2yun.com.cn; path=/`;
    } else {
        document.cookie = `${name}=; expires=${expires.toUTCString()}; domain=localhost; path=/`;
    }
}

//  网站布局
class Home extends React.Component {
    constructor() {
        super()
        let version = '2.5.3'
        let token = null
        let user = {}
        let config = { core: [] }
        let isMobile = window.innerWidth <= 768
        const name = 'n2yunToken'
        const cookies = document.cookie.split(';')
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim()
            if (cookie.startsWith(`${name}=`)) {
                token = cookie.substring(name.length + 1)
            }
        }

        if (localStorage.getItem('n2yun_user')) {
            user = JSON.parse(localStorage.getItem('n2yun_user'))
        }
        if (localStorage.getItem('n2yun_web_config')) {
            config = JSON.parse(localStorage.getItem('n2yun_web_config'))
        }

        version = md5(version).substring(md5(version).length - 7)

        this.state = {
            isMobile: isMobile,
            content: 'content',
            token: token,
            user: user,
            login: false,
            modal: true,
            core: 'independent',
            version: version,
            config: config,
            width: window.innerWidth,
            height: window.innerHeight,
        }
    }

    componentDidMount() {
        NProgress.start()
        this.verifyToken()

        api.get('/api/v2/get_web_config')
            .then((res) => {
                if (res.data.code === 200) {
                    this.setState({
                        config: res.data.data.data.Data
                    })
                    localStorage.setItem('n2yun_web_config', JSON.stringify(res.data.data.data.Data))
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
        NProgress.done()
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.state !== nextState
    }

    clear = () => {
        return (
            <div style={{ clear: 'both' }}></div>
        )
    }

    //  清理 Cookie
    clearCookie = clearCookie

    //  设置 Cookie
    setCookie = (name, value) => {
        const now = new Date()
        const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000)
        if (process.env.NODE_ENV === 'production') {
            document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; domain=.n2yun.com.cn; path=/`
        } else {
            document.cookie = `${name}=${value}; expires=${expires.toUTCString()}; domain=localhost; path=/`
        }
    }

    //  用户函数
    function = {
        getAvatar: () => {
            if (this.state.user.avatar) {
                return this.state.user.avatar
            }
            return 'https://ui-avatars.com/api/?background=random&name=' + this.state.user.name
        }
    }

    //  修改父组件 数据状态
    handleChange = (data) => {
        this.setState(data)
    }

    //  获取 Token
    getToken = () => {
        return this.state.token
    }

    //  获取 登录状态
    getLogin = () => {
        return this.state.login
    }

    //  验证权限
    verifyAy = (name) => {
        let role = this.state.user.role
        if (role) {
            for (let i = 0; i < role.length; i++) {
                if (name === role[i]) {
                    return true
                }
            }
        }
        return false
    }

    //  进行验证是否已登录
    verifyToken = () => {
        api.post('/api/v2/user/verify_token')
            .then((res) => {
                if (res.data.code === 200) {
                    localStorage.setItem('n2yun_user', JSON.stringify(res.data.data.data))
                    this.setState({
                        token: res.data.data.data.token,
                        user: res.data.data.data,
                        login: true
                    })
                } else {
                    this.setState({
                        token: null,
                        user: {},
                        login: false
                    })
                    clearCookie('n2yunToken')
                    localStorage.removeItem('n2yun_user')
                }
            })
            .catch((error) => {
                this.setState({
                    token: null,
                    user: {},
                    login: false
                })
                clearCookie('n2yunToken')
                localStorage.removeItem('n2yun_user')
                message.error('网络异常,无法连接服务器')
            })
    }

    handleOk = () => {
        this.setState({ modal: false })
        api.post('/api/v2/user/update', {
            type: 'core',
            value: this.state.core
        })
            .then((res) => {
                if (res.data.code === 200) {
                    message.success('已设置成功,欢迎使用')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }
    handleCancel = () => {
        this.setState({ modal: false })
    }
    onChange = (value) => {
        this.setState({
            core: value
        })
    }

    render() {
        return (
            <BrowserRouter>
                <StyleProvider hashPriority="high">
                    <ConfigProvider
                        locale={zhCN}
                        theme={{
                            "token": {
                                "colorPrimary": "#06a4cb",
                                "colorInfo": "#0088cc",
                                "borderRadius": 4,
                                "wireframe": false
                            }
                        }}
                    >
                        <div>
                            <Nav {...this} />
                            <div className={this.state.isMobile ? 'content-m' : 'content'}>
                                <RouterView {...this} />
                            </div>

                            <div>
                                {this.state.login ?
                                    <div>
                                        {this.state.user.core ? null : <Modal
                                            title="请选择框架版本"
                                            open={this.state.modal}
                                            centered
                                            maskClosable={false}
                                            onOk={this.handleOk}
                                            onCancel={this.handleCancel}
                                        >
                                            <p>选择你当前使用的框架以及版本,方便网站自动匹配你可适用的版本插件/脚本</p>
                                            <Select
                                                style={{
                                                    width: '100%',
                                                }}
                                                onChange={this.onChange}
                                                placeholder="选择当前插件适配的版本"
                                                options={this.state.config.core}
                                                value={this.state.core}
                                            />
                                        </Modal>}
                                    </div>
                                    : null}
                            </div>
                            <Footer style={{ textAlign: 'center' }}>
                                <div >
                                    <Link href="/status" ><HddOutlined /> 节点监控</Link>
                                </div>
                                Copyright © 2020-{new Date().getFullYear()} N2YUN社区 ( <BranchesOutlined />{this.state.version} )
                                {/* Copyright © 2020-{new Date().getFullYear()} N2YUN社区® 广州恩云网络科技工作室 ( <BranchesOutlined />{this.state.version} ) */}
                                <br />
                                本站由<a target='_blank' href='https://www.rainyun.com/n2yun_'> 雨云 </a>提供计算服务
                                <br />
                                <a href='https://beian.miit.gov.cn/'>粤ICP备2021149176号</a>
                            </Footer>
                        </div>
                    </ConfigProvider>
                </StyleProvider>
            </BrowserRouter>
        )
    }
}

//  主页内容
function Index(props) {
    return (
        <div>
            {/* <Alert message={props.state.config.notify} type="info" showIcon={true} icon={<NotificationOutlined />} style={{
                marginTop: props.state.isMobile ? -20 : 0,
                marginBottom: props.state.isMobile ? -20 : 0,
            }} /> */}
            <Region {...props} />
            <FloatButton.Group shape="circle">
                {props.state.token ? <FloatButton href='/edit' /> : null}
                <FloatButton.BackTop />
            </FloatButton.Group>
        </div>
    )
}

// //  QQ快捷登录
// function QqConnect() {
//     const navigate = useNavigate()

//     var url = window.location.href
//     var querys = url.substring(url.indexOf('?') + 1).split('&')
//     var result = []
//     for (var i = 0; i < querys.length; i++) {
//         var temp = querys[i].split('=')
//         if (temp.length < 2) {
//             result[temp[0]] = ''
//         } else {
//             result[temp[0]] = temp[1]
//         }
//     }

//     navigate('/login?access_token=' + result['#access_token'] + '&state=' + result['state'])
// }

//  路由管理
function RouterView(props) {
    const routes = useRoutes([
        { path: "/", element: <Index {...props} /> },
        { path: "/nzadmin", element: <Nzadmin {...props} /> },
        { path: "/notify", element: <Notify {...props} /> },
        { path: "/status", element: <Status {...props} /> },
        { path: "/u/:id", element: <User {...props} /> },
        { path: "/grid/:id", element: <Grid {...props} /> },
        { path: "/grid/:id/:p", element: <Grid {...props} /> },
        { path: "/search/:search", element: <Grid {...props} /> },
        { path: "/t/:id", element: <Article {...props} /> },
        { path: "/edit", element: <Edit {...props} /> },
        { path: "/edit/:id", element: <Edit {...props} /> },
        { path: "/rely_edit", element: <Rely_Edit {...props} /> },
        { path: "/rely_edit/:id", element: <Rely_Edit {...props} /> },
        // { path: "/sso/:id", element: <Sso {...props} /> },
        { path: "/verify/:id", element: <Verify {...props} /> },
        // { path: "/forget_password", element: <ForgetPassword {...props} /> },
        { path: "/app/:id", element: <Console {...props} /> },
        { path: "/admin/:id", element: <Admin {...props} /> },
        { path: "/login", element: <Login {...props} /> },
        { path: "/register", element: <Register {...props} /> },
        // { path: "/api/v2/qq_connect", element: <QqConnect {...props} /> },
        {
            path: "*", element: <Result
                status="404"
                title="404"
                subTitle="您访问的页面已经跑路了"
            />
        }
    ])
    return <>{routes}</>
}

export default Home