import React, { useState, useEffect, useCallback } from 'react'
import api from '../api'

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Button, Tag, Typography, message, Modal, Input, Table, Tooltip, Space, Alert, Image } from 'antd'

import { ProCard } from '@ant-design/pro-components'
import { name } from 'file-loader'

const { Paragraph, Text } = Typography

const columns = [
    {
        title: '功能',
        dataIndex: 'key',
        rowScope: 'row',
    },
    {
        title: '普通会员',
        dataIndex: 'user',
    },
    {
        title: 'NV1会员',
        dataIndex: 'nv1',
    },
    {
        title: 'NV2会员 [停止购买]',
        dataIndex: 'nv2',
    },
    {
        title: '定制服务',
        dataIndex: 'custom',
    },
]



//  控制台-会员中心
function Member(props) {
    const [data, setData] = useState({ gip: [], token: '', member: '普通', ServerData: { ip: null, port: null }, group_id: null })
    const [update, setUpdate] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [details, setDetails] = useState({ modal: null, total_fee: null, qr: null, port: '40268' })
    const [nvID, setNvID] = useState({
        name: 'nv1',
        price: '19'
    })

    const datas = [
        {
            key: 'NZ-admin [已下线维护]',
            user: <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 18 }} />,
            nv1: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
            nv2: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
            custom: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
        },
        // {
        //     key: '代码托管',
        //     user: <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 18 }} />,
        //     nv1: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
        //     custom: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
        // },
        // {
        //     key: '去墙服务',
        //     user: <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 18 }} />,
        //     nv1: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
        //     custom: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
        // },
        {
            key: '去墙服务 [已下线维护]',
            user: <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 18 }} />,
            nv1: <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 18 }} />,
            nv2: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
            custom: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
        },
        {
            key: 'NZ云存储 [已下线]',
            user: <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 18 }} />,
            nv1: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
            nv2: <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 18 }} />,
            custom: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
        },
        // {
        //     key: 'KOOK Bot托管',
        //     user: <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 18 }} />,
        //     nv1: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
        //     custom: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
        // },
        {
            key: '更多定制功能',
            user: <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 18 }} />,
            nv1: <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 18 }} />,
            nv2: <CloseCircleOutlined style={{ color: '#ff4d4f', fontSize: 18 }} />,
            custom: <CheckCircleOutlined style={{ color: '#52c41a', fontSize: 18 }} />,
        },
        {
            key: '费用',
            user: <Tag color="processing">免费</Tag>,
            nv1: <>
                <Tag color="processing">[停止购买] 19元/30天</Tag>
                <Button type="primary" size='small' style={{}} onClick={() => {
                    onClick('buy_membership')
                    setNvID({
                        name: 'nv1',
                        price: '19'
                    })
                }}>停止购买</Button>
            </>,
            nv2:
                <>
                    <Tag color="processing">[停止购买] 15元/30天</Tag>
                    <Button type="primary" size='small' style={{}} onClick={() => {
                        onClick('buy_membership')
                        setNvID({
                            name: 'nv2',
                            price: '15'
                        })
                    }} disabled>停止购买</Button>
                </>
            ,
            custom: <Tooltip title="QQ: 198513272">
                <Tag color="warning">咨询站务</Tag>
            </Tooltip>,
        },
    ]

    useEffect(() => {
        let name = 'userMemberData'
        if (localStorage.getItem(name)) {
            setData(JSON.parse(localStorage.getItem(name)))
        }

        api.get('/api/v2/user/get', {
            params: {
                name: 'member'
            }
        })
            .then((res) => {
                if (res.data.code === 200) {
                    let Data = res.data.data.data.Data
                    localStorage.setItem(name, JSON.stringify(Data))
                    setData(Data)
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [update])

    const resetToken = useCallback(() => {
        api.post('/api/v2/user/update', {
            type: 'gtoken',
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    message.success('已重置Token,请更新使用旧的Token的插件')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [])


    //  取消绑定
    function unbind() {
        api.post('/api/v2/user/update', {
            type: 'unbind',
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    message.success('已取消绑定,可以绑定其他的设备了')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }


    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = useCallback(() => {
        let Data = []

        for (let i = 0; i < data.gip.length; i++) {
            if (data.gip[i].length >= 1) {
                if (!validateIP(data.gip[i])) {
                    if (!validateDomain(data.gip[i])) {
                        message.warning('请检查是否有正确输入IP/域名')
                        return
                    }
                }
                Data.push(data.gip[i])
            }
        }
        setIsModalOpen(false)
        api.post('/api/v2/user/update', {
            type: 'gip',
            value: Data
        })
            .then((res) => {
                if (res.data.code === 200) {
                    setUpdate(Date.now())
                    message.success('修改成功')
                } else {
                    message.error(res.data.data.text)
                }
            })
            .catch((error) => {
                message.error('网络异常,无法连接服务器')
            })
    }, [data])

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const onChange = (e) => {
        let name = parseInt(e.target.name)
        let value = e.target.value

        setData((data) => ({
            ...data,
            gip: [
                ...data.gip.slice(0, name),
                value,
                ...data.gip.slice(name + 1)
            ]
        }))
    }

    /*  检查ip地址 0.0.0.0~255.255.255.255  */
    function validateIP(str) {
        var re =
            /^(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|[0-9])\.((1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.){2}(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)$/
        return re.test(str)
    }

    // 检查域名
    function validateDomain(str) {
        var re =
            /^([0-9a-zA-Z-]{1,}\.)+([a-zA-Z]{2,})$/
        return re.test(str)
    }

    //  操作
    function onClick(name) {
        if (name === 'renew_membership') {
            setDetails({ ...details, modal: 'renew_membership' })
        }
        if (name === 'buy_membership') {
            setDetails({ ...details, modal: 'buy_membership' })
        }
        if (name === 'server_config') {
            setDetails({ ...details, modal: 'server_config' })
        }
        if (name === 'bot_config') {
            setDetails({ ...details, modal: 'bot_config' })
        }


        if (name === 'server_config_yes') {
            if (!details.ip) {
                message.warning('请检查是否有正确输入IP/域名')
                return
            }
            if (!details.port || !parseInt(details.port) || parseInt(details.port) >= 65535 || 0 >= parseInt(details.port)) {
                message.warning('请输入正确格式的监听端口')
                return
            }
            if (!validateIP(details.ip)) {
                if (!validateDomain(details.ip)) {
                    message.warning('请检查是否有正确输入IP/域名')
                    return
                }
            }

            const key = Date
            message.loading({ key, content: '正在检查是否可以通信...', })

            api.post('/api/v2/user/update', {
                type: 'add_server_config',
                value: {
                    ip: details.ip,
                    port: details.port,
                }
            })
                .then((res) => {
                    if (res.data.code === 200) {
                        setUpdate(Date.now())
                        setDetails({ ...details, modal: null })
                        // message.success('操作成功')
                        message.success({ key, content: '操作成功' })
                    } else {
                        // message.error(res.data.data.text)
                        message.error({ key, content: res.data.data.text })
                    }
                })
                .catch((error) => {
                    message.error('网络异常,无法连接服务器')
                })
        }

        if (name === 'bot_config_yes') {
            if (!details.group_id) {
                message.warning('请输入内容再提交')
                return
            }

            api.post('/api/v2/user/update', {
                type: 'add_bot_config',
                value: details.group_id
            })
                .then((res) => {
                    if (res.data.code === 200) {
                        setUpdate(Date.now())
                        setDetails({ ...details, modal: null })
                        message.success('操作成功')
                    } else {
                        message.error(res.data.data.text)
                    }
                })
                .catch((error) => {
                    message.error('网络异常,无法连接服务器')
                })
        }


        if (name === 'renew_membership_yes') {
            api.post('/api/v2/user/pay', {
                type: 'initiate_payment',
                renewal_vip: false,
                member: nvID.name
            })
                .then((res) => {
                    if (res.data.code === 200) {
                        message.success('已获取支付二维码')
                        setDetails({ ...details, modal: 'pay', total_fee: res.data.data.data.total_fee, qr: res.data.data.data.QRcode_url, out_trade_no: res.data.data.data.out_trade_no })
                    } else {
                        message.error(res.data.data.text)
                    }
                })
                .catch((error) => {
                    message.error('网络异常,无法连接服务器')
                })
        }

        if (name === 'buy_membership_yes') {
            api.post('/api/v2/user/pay', {
                type: 'initiate_payment',
                renewal_vip: true,
                member: nvID.name
            })
                .then((res) => {
                    if (res.data.code === 200) {
                        message.success('已获取支付二维码')
                        setDetails({ ...details, modal: 'pay', total_fee: res.data.data.data.total_fee, qr: res.data.data.data.QRcode_url, out_trade_no: res.data.data.data.out_trade_no })
                    } else {
                        message.error(res.data.data.text)
                    }
                })
                .catch((error) => {
                    message.error('网络异常,无法连接服务器')
                })
        }

        if (name === 'pay_yes') {
            api.post('/api/v2/user/pay', {
                type: 'pay_yes',
                out_trade_no: details.out_trade_no,
            })
                .then((res) => {
                    if (res.data.code === 200) {
                        message.success('支付成功, 请刷新页面查看')
                    } else {
                        message.error(res.data.data.text)
                    }
                })
                .catch((error) => {
                    message.error('网络异常,无法连接服务器')
                })
        }
    }

    //  处理会员渲染显示
    function getMember(value, time) {
        if (time) {
            if (data.member !== 'user') {
                if (value) {
                    if (data.expired) {
                        return '已过期'
                    }
                    return value
                }
                return '永不过期'
            }

            return '永不过期'
        }

        if (value === 'nv1') {
            return 'NV1会员'
        }
        if (value === 'nv2') {
            return 'NV2会员'
        }
        if (value === 'nv3') {
            return 'NV3会员'
        }
        return '普通'
    }

    return (
        <div>
            <div style={{ marginBottom: 20 }}>
                {/* <Alert message="目前已暂停所有会员的购买/续费操作, 请耐心等待官方维护完成并通知。" type="error" /> */}

                <Alert
                    message="暂停业务"
                    description="目前已暂停所有会员的购买/续费操作, 请耐心等待官方维护完成并通知。"
                    type="warning"
                    showIcon
                />
            </div>
            {/* <div style={{ marginBottom: 20 }}>
                <Alert message="目前自助购买仅支持微信支付, 如需要使用支付宝支付, 请联系QQ: 198513272 进行人工处理" type="info" />
            </div> */}
            {/* <div style={{ marginBottom: 20 }}>
                <Alert message="请勿同时 购买/续费 NV1、NV2会员, 会进行覆盖写入, 则以最后购买的会员为准, 例如你购买了NV1会员1个月, 不小心再次购买NV2会员时, 会将原来的NV1会员加长时间, 但权益变成了NV2会员, 并非NV1会员。" type="warning" />
            </div> */}

            <ProCard title="会员信息" style={{ maxWidth: 300, float: 'left', marginRight: 20 }} bordered headerBordered>
                {/* 等级: {data.member === 'nv1' ? <>{getMember()} 会员 {data.expired ? <Tag color="error">已过期</Tag> : null}</> : '普通'} */}
                等级: {getMember(data.member)}
                <br />
                状态:   {data.expired ? data.member !== 'user' ? <Tag color="error">已过期</Tag> : <Tag color="success">正常</Tag> : <Tag color="success">正常</Tag>}
                <br />
                过期时间: {getMember(data.etime, true)}
                <br />

                {data.member !== 'user' ?
                    <Button type="primary" size='small' style={{ float: 'right' }} onClick={() => {
                        if (data.member === 'nv1') {
                            setNvID({
                                name: 'nv1',
                                price: '19'
                            })
                        }
                        if (data.member === 'nv2') {
                            setNvID({
                                name: 'nv2',
                                price: '15'
                            })
                        }

                        onClick('renew_membership')
                    }}>续费会员</Button>
                    :
                    null
                    // <Button type="primary" size='small' style={{ float: 'right' }} onClick={() => {
                    //     onClick('buy_membership')
                    // }}>购买会员</Button>
                }
            </ProCard>
            <ProCard title="访问控制" style={{ maxWidth: (Math.floor(props.state.width * 0.6) - 200) - 380, float: 'left' }} bordered headerBordered>
                <Paragraph
                    copyable={{
                        text: `${data.token}`,
                    }}
                    style={{ marginBottom: 0 }}
                >
                    Token: {data.token}
                </Paragraph>
                IP: {
                    data.gip.map((item, index) => (
                        <Tag color="default" key={index}>{item}</Tag>
                    ))
                }
                <br />
                允许绑定IP数量: <Tag color="processing">2</Tag>
                <br />
                <Button size='small' style={{ float: 'right' }} onClick={showModal}>编辑IP</Button>
                <Button type="primary" size='small' style={{ float: 'right', marginRight: 10 }} onClick={resetToken}>重置Token</Button>
            </ProCard>

            <ProCard title="NZ-admin 绑定信息" style={{ maxWidth: '100%', float: 'left', marginTop: 20 }} bordered headerBordered>
                绑定状态: {data.server ? <Tag color="success">已绑定</Tag> : <Tag color="error">未绑定</Tag>}
                <br />
                <Button type="primary" size='small' style={{ float: 'right' }} onClick={unbind}>取消绑定</Button>
            </ProCard>


            {data.member === 'nv3' ?
                <>
                    <ProCard title="服务器配置" style={{ maxWidth: '100%', float: 'left', marginTop: 20 }} bordered headerBordered>
                        <Space direction="vertical" >
                            {/* 状态: {data.server ? <Tag color="success">正常通信</Tag> : <Tag color="error">不可通信</Tag>} */}
                            IP/域名: {data.ServerData.ip}
                            监听端口: {data.ServerData.port}
                        </Space>
                        <Button type="primary" size='small' style={{ float: 'right' }} onClick={() => { onClick('server_config') }}>配置</Button>
                    </ProCard>

                    <ProCard title="Bot 机器人配置" style={{ maxWidth: '100%', float: 'left', marginTop: 20 }} bordered headerBordered>
                        <Space direction="vertical" >
                            {/* 状态: {data.server ? <Tag color="success">正常通信</Tag> : <Tag color="error">不可通信</Tag>} */}
                            Q群ID: {data.group_id}
                        </Space>
                        <Button type="primary" size='small' style={{ float: 'right' }} onClick={() => { onClick('bot_config') }}>配置</Button>
                    </ProCard>
                </>
                :
                null
            }



            <Modal title="编辑IP" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Input placeholder="IP-1" style={{ marginTop: 10 }} name='0' onChange={onChange} value={data.gip[0]} />
                <Input placeholder="IP-2" style={{ marginTop: 10 }} name='1' onChange={onChange} value={data.gip[1]} />
            </Modal>

            <props.clear />

            <Table
                columns={columns}
                dataSource={datas}
                pagination={false}
                style={{
                    marginTop: 20
                }}
                bordered />




            <Modal
                title="服务器配置"
                centered
                open={details.modal === 'server_config'}
                onOk={() => { onClick('server_config_yes') }}
                onCancel={() => { setDetails({ ...details, modal: null }) }}
            >
                <Space direction="vertical" >
                    <Text>说明: 监听端口是在插件的 config.js 文件里面的 Config.port选项, 默认为40268端口, 如有防火墙/安全组, 请开放此端口</Text>

                    <Input placeholder="服务器IP/域名" style={{ marginTop: 10 }} name='ip' onChange={(e) => { setDetails({ ...details, ip: e.target.value }) }} value={details.ip} />
                    <Input placeholder="监听端口" style={{ marginTop: 10 }} name='port' onChange={(e) => { setDetails({ ...details, port: e.target.value }) }} value={details.port} />
                </Space>
            </Modal>

            <Modal
                title="Bot 机器人配置"
                centered
                open={details.modal === 'bot_config'}
                onOk={() => { onClick('bot_config_yes') }}
                onCancel={() => { setDetails({ ...details, modal: null }) }}
            >
                <Space direction="vertical" >
                    <Text>说明: 群ID在群里拉机器人, @机器人 获取群ID 就可以获取到了, 在这里输入即可</Text>

                    <Input placeholder="群ID" style={{ marginTop: 10 }} name='group_id' onChange={(e) => { setDetails({ ...details, group_id: e.target.value }) }} value={details.group_id} />
                </Space>
            </Modal>

            <Modal
                title="续费会员操作"
                centered
                open={details.modal === 'renew_membership'}
                onOk={() => { onClick('renew_membership_yes') }}
                onCancel={() => { setDetails({ ...details, modal: null }) }}
            >
                <Space direction="vertical" >
                    <Text>级别: {nvID.name}会员</Text>
                    <Text>价格: {nvID.price}元/30天</Text>
                    <Text>备注: 目前可用的功能请参考下方的介绍</Text>
                </Space>
            </Modal>

            <Modal
                title="购买会员操作"
                centered
                open={details.modal === 'buy_membership'}
                onOk={() => { onClick('buy_membership_yes') }}
                onCancel={() => { setDetails({ ...details, modal: null }) }}
            >
                <Space direction="vertical" >
                    <Text>级别: {nvID.name}会员</Text>
                    <Text>价格: {nvID.price}元/30天</Text>
                    <Text>备注: 目前可用的功能请参考下方的介绍</Text>
                </Space>
            </Modal>

            <Modal
                title="微信扫码支付"
                centered
                open={details.modal === 'pay'}
                onOk={() => { onClick('pay_yes') }}
                onCancel={() => { setDetails({ ...details, modal: null }) }}
            >
                <Space direction="vertical" >
                    <Text>价格: {details.total_fee}元</Text>
                    <Text>备注: 支付完成后请稍等几秒, 系统自动处理订单, 如超过数分钟仍没到账, 请与站务联系处理。</Text>
                    <Image
                        width={200}
                        src={details.qr}
                    />
                </Space>
            </Modal>
        </div>
    )
}

export default Member;